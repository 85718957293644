{
  "name": "client-app",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build-prod": "ng build --configuration=production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.4",
    "@angular/common": "^18.0.4",
    "@angular/compiler": "^18.0.4",
    "@angular/core": "^18.0.4",
    "@angular/forms": "^18.0.4",
    "@angular/material": "^18.0.4",
    "@angular/material-moment-adapter": "^18.0.4",
    "@angular/platform-browser": "^18.0.4",
    "@angular/platform-browser-dynamic": "^18.0.4",
    "@angular/router": "^18.0.4",
    "@ngx-translate/core": "^15.0.0",
    "@syncfusion/ej2-angular-grids": "^26.2.8",
    "@syncfusion/ej2-angular-pivotview": "^26.2.8",
    "bootstrap": "^5.3.2",
    "moment": "^2.29.4",
    "ng2-pdf-viewer": "^10.0.0",
    "raygun4js": "^2.25.6",
    "rxjs": "~7.5.0",
    "tslib": "^2.3.0",
    "zone.js": "^0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.5",
    "@angular/cli": "~18.0.5",
    "@angular/compiler-cli": "^18.0.4",
    "@types/jasmine": "~4.0.0",
    "jasmine-core": "~4.2.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "typescript": "^5.4.5"
  }
}
