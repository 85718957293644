import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideRouter, RouterModule } from '@angular/router';
import { TranslateLoaderProvider } from './app/core/providers/translate-loader.provider';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { appRoutes } from './app/app.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WindowProvider } from './app/core/providers/window.provider';
import { GlobalErrorHandler } from './app/core/common/global-error-handler';
import { HttpErrorInterceptor } from './app/core/interceptors/http-error.interceptor';
import { LoaderInterceptor } from './app/core/interceptors/loader.interceptor';
import { JwtInterceptor } from './app/core/interceptors/jwt.interceptor';
import { LanguageInterceptor } from './app/core/interceptors/language.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { TranslateMatPaginatorIntl } from './app/core/common/translate-mat-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LanguageService } from './app/core/services/language.service';
import { LocaleProvider } from './app/core/providers/locale.provider';
import { ApiBaseUrlProvider } from './app/core/providers/api-base-url.provider';
import { AppInitializerProvider } from './app/core/providers/app-initializer.provider';
import { registerLocaleData } from '@angular/common';
import localeDECH from '@angular/common/locales/de-CH';
import localeENCH from '@angular/common/locales/en-CH';
import { MatFormFieldDefaultOptionsProvider } from './app/core/providers/mat-form-field-default-options.provider';
import { DateAdapterProvider } from './app/core/providers/date-adapter.provider';
import { MatDateFormatProvider } from './app/core/providers/mat-date-format.provider';
import { ISO8601DateTime } from './app/core/common/iso8601-date-time';
import { registerLicense } from '@syncfusion/ej2-base';

ISO8601DateTime;

// registerLicense(environment.syncfusionLicenseKey);

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeDECH);
registerLocaleData(localeENCH);

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(FormsModule, ReactiveFormsModule, BrowserModule, MatIconModule, TranslateModule.forRoot({
            loader: TranslateLoaderProvider
        }), RouterModule),
        provideRouter(appRoutes, 
            // withPreloading(PreloadAllModules),
            // withDebugTracing()
        ),
        MatFormFieldDefaultOptionsProvider,
        DateAdapterProvider,
        MatDateFormatProvider,
        AppInitializerProvider,
        ApiBaseUrlProvider,
        LocaleProvider,
        LanguageService,
        {
            provide: MatPaginatorIntl,
            useClass: TranslateMatPaginatorIntl
        },
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        WindowProvider,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
  .catch(err => console.error(err));
